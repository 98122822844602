import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible' //布局
import ElementUI from 'element-ui';//elmentui
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Highlight from '../public/highlight';//代码块
Vue.use(Highlight)

// tillusory.com
// Vue.prototype.recordId = "皖ICP备19022562号-1"
// tillusory.cn
// Vue.prototype.recordId = "皖ICP备19022562号-2"
// tillusory.com.cn
Vue.prototype.recordId = "苏ICP备2022001391号-1"

// 导入图片对比组件
import {
  applyPolyfills,
  defineCustomElements,
} from 'img-comparison-slider/loader';

applyPolyfills().then(() => {
  defineCustomElements(window);
});

import AMap from 'vue-amap';//高德地图
Vue.use(AMap);
// 初始化vue-amap
AMap.initAMapApiLoader({
  key: '0fb4bf3f7fe6623e1d2f46ab00688b6d',// 高德key
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.15',
  uiVersion: '1.0' // ui库版本，不配置不加载,
});

import VueAwesomeSwiper from 'vue-awesome-swiper' //轮播
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
